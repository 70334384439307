import { format } from 'date-fns'
import { utils, writeFileXLSX } from 'xlsx'

import { columnKeys as bargeTableKeys, columns as bargeColumns } from '../components/Table/BargesTable'
import {
  columns as nominatedBargeColumns,
  columnKeys as nominatedBargeColumnKeys,
} from '../components/Table/NominatedBargesTable'
import { ELLIPSIS } from '../constants/constants'
import { DEPLOYMENT_ENVIRONMENT } from '../constants/env-vars'
import { OverviewBarge } from '../Domain/Barge'
import { Filter } from '../Domain/BargeFilters'
import { fromRequestFilters, NominatedTow, UserBargeNomination } from '../Domain/Nomination'
import { showRiverLocation } from '../Domain/River'
import { LaneId, LoadStatus, TowConfiguration } from '../generated/graphql'
import { browserInfo } from '../lib/browser-info'
import { UserDetails } from '../Pages/Account/AuthorizationContext'

import { toString } from './date'

const feedbackColumns = bargeTableKeys.filter(
  key => !['boatId', 'eta95', 'eta80', 'id', 'fleetId', 'startTime'].includes(key)
)

export function collectFeedbackData(
  userInfo: UserDetails,
  lane: LaneId,
  { origin, destination, excludeTanks, excludeHoppers, excludeTboInfo, excludePlacedToLoad, excludeShuttleMoves, time, maxDraft }: Filter,
  input: {
    prioritizeHotBarges: boolean
    towConfiguration: TowConfiguration
  },
  tows: NominatedTow[],
  barges: OverviewBarge[],
  recordTime?: Date
) {
  const { email, cognitoUsername } = userInfo
  const info = browserInfo()
  const book = utils.book_new()

  utils.book_append_sheet(
    book,
    utils.aoa_to_sheet([
      ['info'],
      ['browser', info.browser],
      ['browser version', info.browserVersion],
      ['os', info.os],
      ['device', info.device],
      ['env', DEPLOYMENT_ENVIRONMENT],
      [],
      ['user'],
      ['email', email],
      ['username', cognitoUsername],
      [],
      ['input'],
      ['lane', lane],
      ['origin', origin],
      ['destination', destination],
      ['exclude tanks', excludeTanks],
      ['exclude open hopper', excludeHoppers],
      ['exclude tbo info barges', excludeTboInfo],
      ['exclude placed to load barges', excludePlacedToLoad],
      ['exclude shuttle moves', excludeShuttleMoves],
      ['timeWindow', time ? toString(new Date(time)) : ELLIPSIS],
      ['maxDraft', maxDraft ?? ELLIPSIS],
      ['prioritize hot barges', input.prioritizeHotBarges],
      [],
      ['nomination time', toString(recordTime || new Date()) ?? ELLIPSIS],
      [],
      ['nominations config'],
      ...(input.towConfiguration
        ? [
            ['goal', input.towConfiguration.goal],
            [
              'number of loaded barges',
              input.towConfiguration.numberOfBarges - input.towConfiguration.numberOfEmptyBarges,
            ],
            ['number of empties', input.towConfiguration.numberOfEmptyBarges],
            ['pre-selected barges', input.towConfiguration.preselectedBarges.join(', ')],
            [],
          ]
        : []),
    ]),
    'Feedback'
  )

  if (tows.length) {
    const sheetData = [nominatedBargeColumnKeys.map(key => nominatedBargeColumns[key].label), []]

    const towRows = tows
      .map((tow, index) => {
        const towData = [
          (index + 1).toString().padStart(2, '0'),
          'list',
          `goal: ${input.towConfiguration.goal}`,
          '',
          tow.boat ? `boat: #${tow.boat.boatId} - ${tow.boat.name ?? 'N/A'}` : 'N/A',
          '',
        ].map(value => value ?? '')

        type RouteSummary = Record<string, { Empty: number; Loaded: number }>

        const tbnData = tow.tbnBarges.reduce<RouteSummary>((acc, barge) => {
          const route = {
            pickup: barge.pickupFacility,
            dropoff: barge.dropOffFacility,
          }
          const key: LoadStatus =
            barge.expectedLoadStatus?.toLowerCase() === 'loaded' ? LoadStatus.Loaded : LoadStatus.Empty
          const pickupLocation = showRiverLocation(route.pickup)
          const dropoffLocation = showRiverLocation(route.dropoff)
          const routeString = `${pickupLocation} to ${dropoffLocation}`
          if (!acc[routeString]) acc[routeString] = { Empty: 0, Loaded: 0 }
          acc[routeString][key] += 1

          return acc
        }, {} as RouteSummary)

        const tbnEntries = [
          [],
          [],
          ['', 'TBNs:'],
          ['', 'Pickup', 'Drop-off', 'Number of loaded', 'Number of empty'],
          ...Object.entries(tbnData).map(([routeString, counts]) => {
            const [pickupLocation, dropoffLocation] = routeString.split(' to ')
            return ['', pickupLocation, dropoffLocation, counts.Loaded.toString(), counts.Empty.toString()]
          }),
        ]

        return [
          towData,
          ...tow.barges.map(barge =>
            nominatedBargeColumnKeys.map(key => String(nominatedBargeColumns[key].format(barge) ?? ''))
          ),
          ...tbnEntries,
        ]
      })
      .flat()

    sheetData.push(...towRows)

    utils.book_append_sheet(book, utils.aoa_to_sheet(sheetData), 'Nominations')
  }

  utils.book_append_sheet(
    book,
    utils.aoa_to_sheet([
      feedbackColumns,
      ...barges.map(barge => feedbackColumns.map(key => bargeColumns[key].format(barge) ?? ELLIPSIS)),
    ]),
    'Barges'
  )

  const exportDate = format(new Date(), 'yyyy-MM-dd')
  const exportFileName = `feedback-${lane}-${exportDate}.xlsx`

  book.Sheets.Feedback['!cols'] = [{ wch: 20 }]

  return writeFileXLSX(book, exportFileName)
}

export function exportNominationData(userInfo: UserDetails, nomination: UserBargeNomination, barges: OverviewBarge[]) {
  const {
    tows,
    userRequest: { bargeFilters, towConfiguration, prioritizeHotBarges },
    recordTime
  } = nomination

  const filters = fromRequestFilters(bargeFilters)

  return collectFeedbackData(
    userInfo,
    nomination.userRequest.bargeFilters.lane,
    filters,
    { prioritizeHotBarges, towConfiguration },
    tows,
    barges ?? [],
    recordTime
  )
}
