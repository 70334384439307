import type { FacilityLocation } from './Facility'
import type { RiverLocationLite } from '../generated/graphql'

// TODO: maybe move to lib/formatters
export const showRiverLocation = ({ code, mileagePoint }: Pick<RiverLocationLite, 'code' | 'mileagePoint'>): string =>
  `${code} ${mileagePoint.toFixed(1)}`

export const equals = (a: RiverLocationLite | FacilityLocation, b: RiverLocationLite | FacilityLocation) =>
  a.code === b.code && a.mileagePoint === b.mileagePoint

export const OH_BASIN = ['OH', 'TEN', 'CUM', 'ALL', 'MON', 'BS', 'KAN', 'GR', 'HWR', 'BARC', 'CLI', 'EMO']

