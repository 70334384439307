import React, { FC } from 'react'

import { ToggleExcludeKeys } from '../../../../Domain/BargeType'
import { Toggle } from '../../../../ui/Toggle/Toggle'

import styles from './styles.module.scss'


type PoolFiltersSelectorProps = {
    nominatedBarges: boolean
    tanks: boolean
    openHopper: boolean
    havingTboInfo: boolean
    placedToLoad: boolean
    shuttleMoves: boolean
    onToggle: (key: string, value: boolean) => void
}

const PoolFiltersSelectorBlock: FC<PoolFiltersSelectorProps> = ({
    nominatedBarges,
    tanks,
    openHopper,
    havingTboInfo,
    placedToLoad,
    shuttleMoves,
    onToggle,
}) => {
    const handleToggle = (key: string, value: boolean) => {
        onToggle(key, value)
    }

    return (
        <div className={styles.toggleContainer}>
            <label className={styles.label}>Exclude</label>

            <div className={styles.flexPoolFilters}>
                <span>Nominated barges</span>
                <Toggle enabled={nominatedBarges} onChange={value => handleToggle(ToggleExcludeKeys.NOMINATED_BARGES, value)} />
            </div>
            <div className={styles.flexPoolFilters}>
                <span>Tanks</span>
                <Toggle enabled={tanks} onChange={value => handleToggle(ToggleExcludeKeys.TANKS, value)} />
            </div>
            <div className={styles.flexPoolFilters}>
                <span>Open hopper</span>
                <Toggle enabled={openHopper} onChange={value => handleToggle(ToggleExcludeKeys.OPEN_HOPPER, value)} />
            </div>
            <div className={styles.flexPoolFilters}>
                <span>Having TBO info</span>
                <Toggle enabled={havingTboInfo} onChange={value => handleToggle(ToggleExcludeKeys.HAVING_TBO_INFO, value)} />
            </div>
            <div className={styles.flexPoolFilters}>
                <span>Placed to load</span>
                <Toggle enabled={placedToLoad} onChange={value => handleToggle(ToggleExcludeKeys.PLACED_TO_LOAD, value)} />
            </div>
            <div className={styles.flexPoolFilters}>
                <span>Exclude shuttle</span>
                <Toggle enabled={shuttleMoves} onChange={value => handleToggle(ToggleExcludeKeys.SHUTTLE_MOVES, value)} />
            </div>
        </div>
    )
}

export default PoolFiltersSelectorBlock
