import { useCallback, useEffect, useMemo, useState } from 'react'

import { Link, SportsScore } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import classnames from 'classnames'
import { match } from 'ts-pattern'
import { colorFromUuid } from 'uuid-color'

import { ELLIPSIS } from '../../constants/constants'
import { getCompleteBargeGroupsIds, OverviewBarge, showBargeType } from '../../Domain/Barge'
import { OH_BASIN, showRiverLocation } from '../../Domain/River'
import { isTripStatus, showShortLoadStatus, showTripStatus } from '../../Domain/Trip'
import { LaneId, LoadStatus } from '../../generated/graphql'
import { define, Sortable, SortingState, useSorting } from '../../lib/Column'
import { map } from '../../lib/Nullable'
import { useSettingsContext } from '../../providers/SettingsProvider'
import { Cross } from '../../ui/Cross/Cross'
import { Pin } from '../../ui/Pin/Pin'
import { FilterColunm } from '../../ui/Table/FilterColumn/FilterColumn'
import { SortableColumn } from '../../ui/Table/SortColumn/SortColumn'
import { HeaderCell, TB, TBHead, TBBody, TBR } from '../../ui/Table/Table'
import { TableCell } from '../../ui/Table/TableCell'
import { ColorByType, getColorClass } from '../../ui/Table/TableColumnConfig'
import { durationComponentsFromSeconds, toString } from '../../utils/date'
import { InformationModal } from '../Modal/InformationModal'

import { doGroupOverviewBarges, groupOverviewBarges } from './bargeGrouping'
import { pickupTypeLabels } from './NominatedBargesTable'
import { RiskLevelEntry } from './RiskLevelEntry'
import styles from './Table.module.scss'

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
}

function formatDate<B>(value: B) {
  if (!(value instanceof Date)) {
    return undefined
  }

  return toString(value, DATE_FORMAT)
}

const col = define<OverviewBarge>()

export const columns = {
  name: col.string('Name', _ => _.barge.name),
  cargo: col.string('Cargo', ({ currentTrip: { loadStatus, cargo } }) => {
    const res = []

    if (loadStatus) res.push(showShortLoadStatus(loadStatus))
    if (cargo) res.push(cargo)

    return res.length ? res.join(' ') : null
  }),
  origin: col.string('Origin', ({ currentTrip: { origin } }) =>
    map(origin, o => {
      const { name } = o

      return `${showRiverLocation(o)} ${name ?? '…'}`
    })
  ),
  destination: col.string('Destination', ({ currentTrip: { destination } }) =>
    map(destination, d => {
      const { name } = d

      return `${showRiverLocation(d)} ${name ?? '…'}`
    })
  ),

  dropOff: col.string('Next Drop-off', ({ nextDropOffDetails }) => {
    if (!nextDropOffDetails) return null

    const { estimatedDropOffTime, facility } = nextDropOffDetails
    const eta = formatDate(estimatedDropOffTime) ?? null
    const facilityName = facility ? `${showRiverLocation(facility)} ${facility.name ?? ''}` : null

    return [facilityName, eta].filter(a => a !== null).join(' | ')
  }),

  pickupFacility: col.string('Nomination pickup', _ => map(_.pickupFacility, showRiverLocation)),

  dropOffFacility: col.string('Nomination drop-off', _ => map(_.dropOffFacility, showRiverLocation)),

  timeInFleet: col.number('Days In Fleet', _ => _.timeInFleet, {
    format: value =>
      map(value, _ => {
        const { week, day } = durationComponentsFromSeconds(_)

        return `${week * 7 + day}`
      }) ?? ELLIPSIS,
  }),
  type: col.string('Type', _ => showBargeType(_.barge.type)),
  status: col.string('Custody Status', row => row.status),
  tripStatus: col.string('Trip Status', row => row.currentTrip.status),
  isHot: col.boolean('Hot', row => row.isHot),
  hullType: col.string('Hull Type', row => row.barge.hullType),
  boatName: col.string('Boat Name', row => row.custodyInfo.boat?.name?.toLocaleUpperCase()),
  startTime: col.date('Trip Start', row => row.currentTrip.startTime, {
    format: _ => formatDate(_) ?? null,
  }),
  currentLocation: col.string('Current Location', ({ currentLocation: { riverLocation } }) =>
    showRiverLocation(riverLocation)
  ),
  fleetName: col.string('Fleet Name', ({ custodyInfo }) => {
    return custodyInfo?.fleet?.name ?? ELLIPSIS
  }),
  currentCustody: col.string('Current custody', ({ custodyInfo: { custody } }) => custody),

  actualDraft: col.number('Actual Draft', ({ currentTrip: { actualDraft } }) => actualDraft, {
    format: val => map(val, v => `${Math.floor(v / 12)}'${v % 12}"`) ?? null,
  }),
  isAtRisk: col.string('Risk level', _ => _.riskLevel),
  tboInfo: col.string('Latest TBO', ({ latestTBO }) => latestTBO?.info),
  tboDropOffFacility: col.string('Latest TBO (Drop-off)', ({ latestTBO }) => latestTBO?.dropFacility),
  tripStatusTime: col.date('Trip Status Time', ({ currentTrip: { tripStatusTime } }) => tripStatusTime, {
    format: _ => formatDate(_) ?? null,
  }),
  pickupType: col.string('Pickup Type', row => (row.pickupType ? pickupTypeLabels[row.pickupType] : null)),
}

const defaultHighlightMode = ColorByType.None

const isOhioBasin = (destinationCode?: string) => !!(destinationCode && OH_BASIN.includes(destinationCode))

type ColorExtractorFunction = (barge: OverviewBarge) => boolean

const colorByFunctions: Record<ColorByType, ColorExtractorFunction> = {
  [ColorByType.Hot]: (barge: OverviewBarge) => barge.isHot,
  [ColorByType.Empty]: (barge: OverviewBarge) => barge.expectedTripLoadStatus === LoadStatus.Empty,
  [ColorByType.OhioBasin]: (barge: OverviewBarge) => isOhioBasin(barge.currentTrip?.destination?.code),
  [ColorByType.None]: () => false,
}

const getRowColorClass = (barge: OverviewBarge, colorBy: ColorByType) => {
  const toBeHighlighted = colorByFunctions[colorBy]
  const highlightMode = toBeHighlighted(barge) ? colorBy : defaultHighlightMode
  return getColorClass(highlightMode)
}

export type ColumnKey = keyof typeof columns

export const columnKeys: ColumnKey[] = [
  'name',
  'cargo',
  'origin',
  'destination',
  'dropOff',
  'pickupFacility',
  'dropOffFacility',
  'timeInFleet',
  'type',
  'status',
  'tripStatus',
  'isHot',
  'hullType',
  'boatName',
  'startTime',
  'currentLocation',
  'currentCustody',
  'actualDraft',
  'isAtRisk',
  'tboInfo',
  'tboDropOffFacility',
  'tripStatusTime',
  'fleetName',
  'pickupType',
]

export const DEFAULT_HIDDEN_COLUMNS: ColumnKey[] = [
  'type',
  'status',
  'tripStatus',
  'isHot',
  'hullType',
  'startTime',
  'currentLocation',
  'currentCustody',
  'actualDraft',
  'tboInfo',
  'tboDropOffFacility',
  'tripStatusTime',
  'pickupFacility',
  'dropOffFacility',
  'isAtRisk',
  'fleetName',
]

export const getGroupedDataByColumns = (barges: OverviewBarge[], lane?: LaneId) =>
  doGroupOverviewBarges(
    barges,
    (group, { barges: groupBarges }) => getDataByColumns(groupBarges, { Location: group }),
    lane
  )

export const getDataByColumns = (barges: OverviewBarge[], init: Record<string, string> = {}) =>
  barges.map(b =>
    columnKeys.reduce(
      (acc: Record<string, string>, key) => {
        const c = columns[key]

        acc[c.label] = c.format(b) ?? ELLIPSIS

        return acc
      },
      { ...init }
    )
  )

const BargesTableHeader = ({
  keys,
  sorting,
  handleSorting,
  setFiltering,
  isFiltered,
}: {
  keys: ColumnKey[]
  sorting?: SortingState<OverviewBarge>
  handleSorting: (column: Sortable<OverviewBarge>) => void
  isFiltered: boolean
  setFiltering: (search?: string) => void
}) => (
  <>
    {keys.map(key => (
      <HeaderCell key={key}>
        {key === 'name' ? (
          <FilterColunm handleChange={setFiltering} isFiltered={isFiltered}>
            {columns[key].label}
          </FilterColunm>
        ) : (
          <SortableColumn
            isSorted={columns[key] === sorting?.sortable ? sorting?.dir : undefined}
            handleToggleSorting={() => handleSorting(columns[key])}>
            {columns[key].label}
          </SortableColumn>
        )}
      </HeaderCell>
    ))}
  </>
)

function BargesTableRow({
  row,
  keys,
  validBargeGroupIds,
}: {
  row: OverviewBarge
  keys: ColumnKey[]
  validBargeGroupIds: string[]
}) {
  const { tripStatuses } = useSettingsContext()
  return (
    <>
      <TableCell className={styles.icons}>
        {row.group && validBargeGroupIds.includes(row.group.uuid) && row.group.barges.includes(row.barge.id) ? (
          <Tooltip title={`Part of group: ${row.group.uuid}`} placement="top-end">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link style={{ color: colorFromUuid(row.group.uuid) }} />
          </Tooltip>
        ) : (
          <span />
        )}
        {row.nextDropOffDetails?.willBeDroppedAtNextStop ? (
          <Tooltip title="Dropped off at next stop" placement="top-end">
            <SportsScore />
          </Tooltip>
        ) : (
          <span />
        )}
      </TableCell>
      {keys.map(key => {
        return (
          <TableCell
            className={classnames({
              [styles.isDerived]: ['pickupLocation', 'dropOffLocation'].includes(key),
            })}
            key={key}>
            {match(key)
              .with('isAtRisk', () => <RiskLevelEntry risk={columns.isAtRisk.getValue(row)} />)
              .with('tripStatus', () => {
                const val = columns.tripStatus.getValue(row)

                return isTripStatus(val) ? showTripStatus(val, tripStatuses) : null
              })
              .otherwise(() => {
                const c = columns[key]
                return c.format(row)
              })}
          </TableCell>
        )
      })}
      <td key="stretch" className={styles.stretcher} />
    </>
  )
}

function PinCell({
  bargeIds,
  guard,
  pinBarges,
  unpinBarges,
  isPinned,
  className,
}: {
  bargeIds: string[]
  guard: (fn: () => void) => void
  className?: string
  isPinned: boolean
  pinBarges: (ids: string[]) => void
  unpinBarges: (ids: string[]) => void
}) {
  const togglePin = useCallback(
    () => guard(() => (isPinned ? unpinBarges(bargeIds) : pinBarges(bargeIds))),
    [guard, isPinned, bargeIds, pinBarges, unpinBarges]
  )

  return (
    <TableCell className={classnames(styles.tablePinCell, className)}>
      <button onClick={togglePin}>
        <Pin isPinned={isPinned} />
      </button>
    </TableCell>
  )
}

function ExcludeCell({
  bargeIds,
  guard,
  excludeBarges,
  unexcludeBarges,
  isExcluded,
  className,
}: {
  bargeIds: string[]
  guard: (fn: () => void) => void
  className?: string
  isExcluded: boolean
  excludeBarges: (ids: string[]) => void
  unexcludeBarges: (ids: string[]) => void
}) {
  const toggleExclude = useCallback(
    () => guard(() => (isExcluded ? unexcludeBarges(bargeIds) : excludeBarges(bargeIds))),
    [guard, isExcluded, bargeIds, excludeBarges, unexcludeBarges]
  )

  return (
    <TableCell className={classnames(styles.tablePinCell, className)}>
      <button onClick={toggleExclude}>
        <Cross isExcluded={isExcluded} />
      </button>
    </TableCell>
  )
}

type BargesTableProps = {
  lane?: LaneId
  barges: OverviewBarge[]
  columns: ColumnKey[]
  setIsGrouped: (isGrouped: boolean) => void
  isSelectable: () => boolean
  pinnedBarges: string[]
  setPinnedBarges: (ids: string[]) => void
  excludedBarges: string[]
  setExcludedBarges: (ids: string[]) => void
  colorBy: ColorByType
  colorByOptions?: ColorByType[]
  handleColorByChange?: (key: ColorByType) => void
}

export function BargesTable({
  lane,
  barges,
  columns: configuredColumns,
  setIsGrouped,
  isSelectable,
  pinnedBarges,
  setPinnedBarges,
  excludedBarges,
  setExcludedBarges,
  colorBy,
}: BargesTableProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [filtering, setFiltering] = useState<string>()
  const { sorting, setSorting } = useSorting<OverviewBarge>()

  const pinBarges = useCallback(
    (ids: string[]) => setPinnedBarges([...pinnedBarges.filter(bargeId => !ids.includes(bargeId)), ...ids]),
    [setPinnedBarges, pinnedBarges]
  )

  const unpinBarges = useCallback(
    (ids: string[]) => setPinnedBarges(pinnedBarges.filter(bargeId => !ids.includes(bargeId))),
    [setPinnedBarges, pinnedBarges]
  )

  const excludeBarges = useCallback(
    (ids: string[]) => setExcludedBarges([...excludedBarges.filter(bargeId => !ids.includes(bargeId)), ...ids]),
    [setExcludedBarges, excludedBarges]
  )

  const unexcludeBarges = useCallback(
    (ids: string[]) => setExcludedBarges(excludedBarges.filter(bargeId => !ids.includes(bargeId))),
    [setExcludedBarges, excludedBarges]
  )

  const guard = useCallback(
    (fn: () => void) => {
      if (isSelectable()) {
        return fn()
      }

      return setIsDialogOpen(true)
    },
    [setIsDialogOpen, isSelectable]
  )

  useEffect(() => {
    setIsGrouped(sorting === undefined && filtering === undefined)
  }, [sorting, filtering, setIsGrouped])

  const validBargeGroupIds = useMemo(() => getCompleteBargeGroupsIds(barges), [barges])

  const rows = useMemo(() => {
    if (sorting !== undefined) {
      const { sortable, dir } = sorting
      const sortedBarges = sortable.sortBy(barges)

      return dir === 'desc' ? sortedBarges.reverse() : sortedBarges
    }

    if (filtering !== undefined && filtering.length) {
      const search = filtering.trim().toLowerCase()

      return barges.filter(({ barge: { name } }) => name !== null && name.toLowerCase().includes(search))
    }

    return groupOverviewBarges(barges, lane)
  }, [barges, sorting, filtering, lane])


  return (
    <>
      <div className={styles.tableContainer}>
        <TB className={styles.table}>
          <TBHead>
            <TBR>
              <HeaderCell className={styles.actionCell}>Pin</HeaderCell>
              <HeaderCell className={styles.actionCell}>Exclude</HeaderCell>
              <HeaderCell size={10} />
              <BargesTableHeader
                keys={configuredColumns}
                sorting={sorting}
                handleSorting={setSorting}
                isFiltered={filtering !== undefined}
                setFiltering={setFiltering}
              />
              <HeaderCell size={99_999} />
            </TBR>
          </TBHead>
          <TBBody>
            {rows.map((row, index) => {
              return 'kind' in row ? (
                <TBR key={row.key}>
                  <TableCell colSpan={columnKeys.length + 2} className={styles.groupHeader}>
                    <span key="key" className={styles.element}>
                      {row.key}
                    </span>
                    {row.type === 'boat' ? (
                      <span key="next-stop" className={styles.element}>
                        {row.boatInfo ?? 'N/A'}
                      </span>
                    ) : null}
                    <span key="rakes" className={styles.element}>
                      Rakes {row.rakes}
                    </span>
                    <span key="boxes" className={styles.element}>
                      Boxes {row.boxes}
                    </span>
                    <span key="LD" className={styles.element}>
                      Loads {row.LD}
                    </span>
                    <span key="MT" className={styles.element}>
                      Empties {row.MT}
                    </span>
                    <span key="tank" className={styles.element}>
                      Tank {row.tank}
                    </span>
                  </TableCell>
                </TBR>
              ) : (
                <TBR
                  key={`${row.barge.id}-${index}`}
                  isOdd={sorting !== undefined && index % 2 > 0}
                  className={styles[getRowColorClass(row, colorBy)]}>
                  <PinCell
                    key={`${row.barge.id}-${index}-pin`}
                    pinBarges={pinBarges}
                    unpinBarges={unpinBarges}
                    isPinned={pinnedBarges.includes(row.barge.id)}
                    bargeIds={[row.barge.id]}
                    guard={guard}
                  />
                  <ExcludeCell
                    bargeIds={[row.barge.id]}
                    guard={guard}
                    isExcluded={excludedBarges.includes(row.barge.id)}
                    excludeBarges={excludeBarges}
                    unexcludeBarges={unexcludeBarges}
                  />
                  <BargesTableRow keys={configuredColumns} row={row} validBargeGroupIds={validBargeGroupIds} />
                </TBR>
              )
            })}
          </TBBody>
        </TB>
      </div>
      <InformationModal
        withCloseButton
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        title="Origin/destination required"
        body={
          <>
            To preselect a barge for nomination, origin and destination are required.
            <br />
            Please select an origin and a destination
          </>
        }
      />
    </>
  )
}
